import '../style/main.scss';


$('.dropdown-menu__item , .dropdown-menu__sub-item').on('click', function () {
    $(this).parent('.dropdown-menu').toggleClass('dropdown-menu_open')
})

$('.submenu-action').on('click', function () {
    $(this).siblings('.mobile-menu__sub').slideToggle(300)
    $(this).toggleClass('active')
})
$('.nav__item a ').on('click', function (e) {
    var href = $(this).attr('href')
    if (href[0] === '#') {

        e.preventDefault()
        scrollTo(href)
        $('body').removeClass('mobile-menu-open')
    }

})
$('.dropdown__item').on('click', function (e) {
   const name = $(this).html()
    const ind = $(this).index()

    $(this).parent('.dropdown__items').siblings('.dropdown__title').find('span').html(name)
    $(this).parents('.schedule-block__top').siblings('.schedule-block__content-wrap').find('.schedule-block__content-wrapper').hide().eq(ind).show()
    $(this).parents('.schedule-block__top').siblings('.schedule-block-slider-wrap').find('.schedule-block-slider').hide().eq(ind).show()



})
$('.schedule-block__button').on('click', function (e) {
    $(this).siblings('.schedule-block__button').removeClass('active')
    $(this).addClass('active')


    const ind = $(this).index()
    $(this).parents('.schedule-block__top').siblings('.schedule-block__content-wrap').find('.schedule-block__content-wrapper').hide().eq(ind).show()




})

function scrollTo(el) {
    $([document.documentElement, document.body]).animate({scrollTop: $(el).offset().top}, 1500)
}

function sendCallBack(subject, name, phone, mail, text) {
    $.ajax({
        type: "POST",
        url: 'https://customersuccess.ru/static/backend/mailController.php',
        data: {
            subject: subject,
            name: name,
            phone: phone,
            mail: mail,
            text: text
        },
        success: function () {

                $('.popup').addClass('hide')
                $('.popup2').removeClass('hide')


        }
    });
}

$('.callback').on('click', function (e) {
    $('.form__input').removeClass('error-input')
    e.preventDefault()
let subject = ''
    const name = $(this).parents('.form').find('input[name="name"]').val()
    const phone = $(this).parents('.form').find('input[name="phone"]').val()
    const mail = $(this).parents('.form').find('input[name="mail"]').val()
    const text = $(this).parents('.form').find('textarea[name="text"]').val()

    if($(this).hasClass('subject-advising')){
         subject = 'ADVISING'

    }
    if($(this).hasClass('subject-supervise')){
         subject = 'SUPERVISE'

    }
    if($(this).hasClass('subject-onboarding')){
         subject = 'ONBOARDING'

    }

    if (!name) {
        $('input[name="name"]').parents('.form__input').addClass('error-input')

    }
    if (!phone) {
        $('input[name="phone"]').parents('.form__input').addClass('error-input')

    }
    if (!mail) {
        $('input[name="mail"]').parents('.form__input').addClass('error-input')

    }
    if (!phone || !name || !mail) {

        console.log('fail')
        return false
    }
console.log(subject)
    sendCallBack(subject, name, phone, mail, text)

});
$('input[name="name"]').on('focus', function () {
    $(this).parents('.form__item').removeClass('error-input')
})
$('input[name="phone"]').on('focus', function () {
    $(this).parents('.form__item').removeClass('error-input')
})
$('.header__mobile-button').on('click', function () {

        if($('body').hasClass('mobile-menu-open') || $('body').hasClass( 'menu-tournaments') || $('body').hasClass('menu-team')){
            $('body').removeClass('mobile-menu-open')
            $('body').removeClass('menu-tournaments')
            $('body').removeClass('menu-team')

        }
        else{

        $('body').addClass('mobile-menu-open')
    }


})
$('.header__mobile-menu-cross').on('click', function () {
    $('body').removeClass('mobile-menu-open')
})
$('.popup__button-close').on('click', function () {
    $('.popup').addClass('hide')

})
$('.popup__close').on('click', function () {
    $('.popup').addClass('hide')
    console.log(123)
})

$('body').on('click', function (e) {

    const div = $(".header__mobile-button");
    const div2 = $(".dropdown__title");
    const div3 = $(".header__nav-item_team");
    const div4 = $(".header__tournaments");
    const div5 = $(".header__log");
    const div6 = $(".header__nav-item_tournaments");
    const div7 = $('.team__head-info-button');
    const div8 = $('.footer-bottom__nav-item');

    const div9 = $(".sec1__application-button");
    const div10 = $('.sec6__item-button');
    const div11 = $('.header__mobile-menu');
    const div12 = $('.footer-form');


    if (!div.is(e.target)
        && div.has(e.target).length === 0
        && !div2.is(e.target)
        && div2.has(e.target).length === 0
        && !div3.is(e.target)
        && div3.has(e.target).length === 0
        && !div4.is(e.target)
        && div4.has(e.target).length === 0
        && !div5.is(e.target)
        && div5.has(e.target).length === 0
        && !div6.is(e.target)
        && div6.has(e.target).length === 0
        && !div7.is(e.target)
        && div7.has(e.target).length === 0
        && !div8.is(e.target)
        && div8.has(e.target).length === 0
        && !div9.is(e.target)
        && div9.has(e.target).length === 0
        && !div10.is(e.target)
        && div10.has(e.target).length === 0
        && !div11.is(e.target)
        && div11.has(e.target).length === 0
        && !div12.is(e.target)
        && div12.has(e.target).length === 0
        )
    {

        $('.dropdown').removeClass('active')
        $('.popup').addClass('hide')
        $('.header__nav-item').removeClass('active')

        $('body').removeClass('mobile-menu-open')
        $('body').removeClass('mobile-menu-open')
        $('body').removeClass('menu-team')
        $('body').removeClass('menu-tournaments')

        $('body').removeClass('team-info')
        $('body').removeClass('tournaments-open')
        $('body').removeClass('mobile-menu-open, mobile-filter-open, team-open')
    }
});

$('body').on('keyup', function (e) {
    if (e.keyCode === 27) {
        $('body').removeClass('mobile-menu-open')
        $('body').removeClass('popup-open')
        $('body').removeClass('search-open')
        $('.popup').addClass('hide')
    }

});

$('.header a, .footer a').on('click', function (e) {
    var href = $(this).attr('href')
    if (href[0] === '#') {
        if($(location).attr('pathname') !== "/" )
        {

            window.location.href = '/'
        }
            e.preventDefault()
            scrollTo(href)
    }

})
function scrollTo(el) {
    $([document.documentElement, document.body]).animate({scrollTop: $(el).offset().top}, 1500)
}


$('.sec1__application-button, .sec1__application-button, .footer-form').on('click', function () {
    $('.popup1').removeClass('hide')

});
$('#onboarding').on('click', function () {
     $('.form__input').removeClass('error-input')
    $('.popup-onboarding').removeClass('hide')
});
$('#supervise').on('click', function () {
     $('.form__input').removeClass('error-input')
    $('.popup-supervise').removeClass('hide')
});
$('#advising').on('click', function () {
     $('.form__input').removeClass('error-input')
    $('.popup-advising').removeClass('hide')
});


$('.popup-cross').on('click', function () {
    $('body').removeClass('popup-open')
    $(this).parents('.popup').addClass('hide')
});
$('.header__sing').on('click', function () {
    $('body').addClass('popup-open')
    $('.popup1').removeClass('hide')
});
$('.header__log').on('click', function () {
    $('body').addClass('popup-open')
    $('.popup2').removeClass('hide')
});
$('.dropdown__title').on('click', function () {
    $(this).parent('.dropdown').toggleClass('active')
});

var swiper = new Swiper(".index-slider", {
    direction: "vertical",
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    mousewheel: {
        releaseOnEdges: true,
    },

});
$('.faq__item-top').on('click', function () {
    $(this).toggleClass('active')
    $(this).siblings('.faq__item-bottom').slideToggle(300)
});

$('.sub-menu-link').on('click', function () {
    $(this).toggleClass('active')
    $(this).find('.sub-sub-menu__list').slideToggle(300)

});
$('.nav__search').on('click', function () {
    $('body').addClass('search-open')

});

var swiper = new Swiper(".main__slider", {
    navigation: {
        nextEl: ".main-swiper-button-next",
        prevEl: ".main-swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});
var swiper = new Swiper(".main__partners-slider", {
    slidesPerView: 5,
    spaceBetween: 60,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        1440: {
            slidesPerView: 5,

        },
        768: {
            slidesPerView: 4.6,

        },
        0: {
            slidesPerView: 1.9,

        },
    },

});
var swiper = new Swiper(".main__score-slider", {
    slidesPerView: 1,

    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },


});
var swiper = new Swiper(".schedule-block-slider", {
    slidesPerView: 1,

    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },


});

$('.team__el').on('click', function (){
    const index = $(this).index()
    $('.team__el').removeClass('active')
    $(this).addClass('active')
    $('.team__member-wrap').hide().eq(index).show()
});
$('.main__news-button').on('click', function (){
    const index = $(this).index()
    $('.main__news-button').removeClass('active')
    $(this).addClass('active')
    $('.main__news-items').hide().eq(index).show()
});$('.team__head-info-button').on('click', function (){
   $('body').toggleClass('team-info')
});

$('.team__member-button').on('click', function (){
    const index = $(this).index()
    $('.team__member-button').removeClass('active')
    $(this).addClass('active')
    $('.team__member-table-wrap').hide().eq(index).show()
});
$('.header__mobile-item_team').on('click', function (e){
    e.preventDefault()

    $('body').addClass('menu-team')
    $('body').removeClass('mobile-menu-open')
    $('body').removeClass('menu-tournaments')
});
$('.header__mobile-item_tournaments').on('click', function (e){
    e.preventDefault()
    $('body').removeClass('menu-team')
    $('body').removeClass('mobile-menu-open')
    $('body').addClass('menu-tournaments')
});
$('.header__team-back').on('click', function (e){
    e.preventDefault()
    $('body').removeClass('menu-team')
    $('body').addClass('mobile-menu-open')
    $('body').removeClass('menu-tournaments')

});
$('.header__nav-item_team').on('click', function (e){

    $('.header__nav-item_tournaments').removeClass('active')


    $('body').addClass('team-open')
    $('body').removeClass('tournaments-open')
    if($('.header__nav-item_team').hasClass('active')){
        $('.header__nav-item_team').removeClass('active')
        $('body').removeClass('team-open')
        $('body').removeClass('tournaments-open')
    }
    else {
        $('.header__nav-item_team').addClass('active')
    }

});
$('.header__nav-item_tournaments').on('click', function (e){
    $('.header__nav-item_team').removeClass('active')
    $('body').addClass('tournaments-open')
    $('body').removeClass('team-open')
    if($('.header__nav-item_tournaments').hasClass('active')){
        $('.header__nav-item_tournaments').removeClass('active')
        $('body').removeClass('team-open')
        $('body').removeClass('tournaments-open')
    }
    else {

        $('.header__nav-item_tournaments').addClass('active')
    }


});
var swiperNav = new Swiper(".main__history-nav", {
    slidesPerView: 6,

    breakpoints: {
        1440: {
            slidesPerView: 6,

        },
        768: {
            slidesPerView: 4,

        },
        0: {
            slidesPerView: 3,

        },
    },


});
var swiperHistory = new Swiper(".main__history-slider", {
    slidesPerView: 1,
    loop: true,


    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".main__history-right",
        prevEl: ".main__history-left",
    },
    thumbs: {
        swiper: swiperNav,
    },


});



